import React from 'react';
import { Link } from 'gatsby'
import Article from '../../components/Article'
import "../../components/App.css"
import "../../components/ArticleContent.css"

export default function SolutionHairloss() {
	
	return (
		<Article
			title='Hair loss'
			takeaway_title='Immediate actions required to stop hairloss'
			page_id='Solution - Hairloss'
			read_time='9 mins'
			author='Brij Bhushan'
			image1='/hair-loss-follicles.jpg'
			image1_description='Anatomy of hair & how hair follicle shrinks in the pattern hair loss'
			image2='/hair-growth-cycle.jpg'
			image2_description='Different phases of hair growth'
			toggle_article='Blog'
			toggle_url='/article/blog_hairloss'
			related_article='Blog : Hairloss'
			takeaway_data={TakeawayData}
			related_articles={RelatedArticles}
		>
				<div className='article_container'>
					<p>
						Broadly, there are two kinds of hair loss. First is called Pattern hair loss (Androgenic alopecia), where hair loss progresses in a pattern (there are separate patterns for males & females). The other is called Bald patches (Alopecia areata), where hairs are lost in patches focussed on a specific part of the scalp. Alopecia areata is an autoimmune disease where the immune system starts targeting hair follicles. Often its cause is a low cortisol condition, triggered due to deep shock to the body by a major loss. In this article, we will primarily discuss Pattern hair loss. <span className="super">[1], [2]</span>
					</p>
					<h3 className='article_heading'>Pattern hair loss</h3>
					<p>
						Male-pattern hair loss typically starts with a receding front hairline along with loss of hair in the middle of the scalp. Female-pattern hair loss typically starts with lowering of hair density across the top of the scalp. <span className="super">[1]</span>
					</p>
					<img src='/hairloss_diagram.jpg' alt="Root cause of hair loww"/>
					<p>
						Healthy hair growth requires various Vitamins and trace minerals. These are Vitamin A, B (esp. Biotin), C & D, and trace minerals like Iron, Copper, Zinc, Silica & Selenium. If a diet doesn’t have the right balance of these nutrients, then it can result in weakening of hairs and eventually hair loss. <span className="super">[3], [4], [5], [6], [7], [8]</span>
					</p>
					<p>
						Hairs are made of protein. When there is shortage of protein in the body, it routes the available proteins to essential parts like muscles, etc. and hairs are considered non-essential. Therefore, a low protein diet can eventually lead to hair loss. Therefore, it is important to have sprouts, pulses, almonds & other sources of protein in the diet. However, stress also reduces the supply of protein in the body, which leads to its low availability for hair growth. <span className="super">[9], [10]</span>
					</p>
					<p>
						Testosterone is a male hormone but it is present in smaller amounts in females also. If its balance with other hormones gets disturbed, it can cause hair loss in males as well as females through the formation of DHT (Dihydrotestosterone). When Testosterone hormone reacts with 5-alpha Reductase enzyme, then DHT is formed. DHT is a significantly more potent hormone than testosterone. It has been observed that high DHT causes hair loss. It shrinks the size of the follicles on the scalp which causes thinning & weakening of hair, and eventually leads to hair loss. <span className="super">[11]</span>
					</p>
					<p>
						High sugar diet (like ice-cream, soft drinks, cookies, candies, cakes and sweets) increases insulin levels in the body & that further increases testosterone. Certain conditions like menopause and PCOS in females, also lead to disturbing the balance of testosterone. This can lead to high DHT. Similarly, consuming Soy protein increases IGF-1 hormone and that also increases DHT. <span className="super">[12], [13]</span>
					</p>
					<p>
						There are multiple theories that link hair loss with heredity. First one emphasises that whether the scalp is sensitive to DHT depends on the AR gene, which is influenced by heredity. Second  theory says that few people are genetically weak (through heredity) in production of 5-alpha Reductase enzyme and those people have relatively less DHT and hence less possibility of hair loss. <span className="super">[14]</span>
					</p>
					<p>
						Thyroid gland produces growth hormones for the body. If thyroid function drops that impacts the growth of new hairs from hair follicles. Pregnancy and consuming birth control pills cause high levels of estrogen hormone and this results in drop of thyroid function. Thyroid’s receptors depend on levels of Vitamin A in the body. In absence of Vitamin A, thyroid fails to secrete sufficient amounts of growth hormones. Vitamin A is fat-soluble and its absorption from food, depends on the amount of bile available in the gut. Shortage of bile can lead to deficiency of Vitamin A and eventually drop in thyroid function. Gut can go short of bile in two cases; first when the liver is weak and it fails to secrete sufficient bile, second when there is lack of good bacteria in the intestine and the body fails to recycle the bile from the intestines. <span className="super">[4], [15], [16]</span>
					</p>
					<p>
						At the end, here is a short note on the third kind of hair loss which happens during some illness or sudden weight loss. It is called Telogen effluvium (Temporary hair loss). There are 3 phases of hair:
						<ol className='article_list'>
							<li>Anagen : It is growth phase where a new hair comes out of a hair follicle</li>
    						<li>Catagen : Hair transitions from growth phase to just maintenance phase</li>
    						<li>Telogen : In this phase, hair is just resting & waiting for a push to fall</li>
    					</ol>
					</p>
					<p>
						While in the previous 2 types of hair loss, impact is on the hair follicle which impacts hairs in the Anagen phase causing permanent damage. In Telogen effluvium, the body perceives a shortage of nutrition which can be due to some illness or sudden weight loss. In this case, hairs in the telogen phase start falling but the body continues nurturing the hairs in the Anagen phase. Therefore, in the short term it appears like a heavy hair loss but the hairs reappear once new hairs grow to their complete length. The temporary hair loss may start within a month of encountering the illness or weight loss. It may continue for 3 to 6 months. It can take upto 1 year for the new hairs to compensate for the hair loss. In this case, one should focus on taking a good diet with complete nutrition with Vitamins, trace minerals & protein. <span className="super">[17]</span>
					</p>
					<h3 className='article_heading'>How to stop hair loss?</h3>
					<p>
						One has to manage the following factors to stop hair loss:
						<ol className='article_list'>
							<li>Good nutrition</li>
    						<li>Sufficient protein in diet</li>
    						<li>Lowering DHT levels</li>
    						<li>Healthy thyroid function</li>
    					</ol>
					</p>
					<p>
						Therefore, the following immediate actions are required:
						<ol className='article_list'>
							<li>Every morning eat 4 curry leaves on an empty stomach. Chew the leaves thoroughly & don’t consume anything else for at least next 30 mins. This is to ensure supply of good nutrition to the body <span className="super">[18], [19]</span></li>
    						<li>Add sprouts, pulses & soaked almonds to the diet, to ensure good intake of proteins. <span className="super">[20]</span> However, sprouts shall be consumed only after steaming them or stir-frying them. Preferably, eat them before sunset and not along with cooked food.</li>
    						<li>Take 1/3 tsp of Amalaki Rasayan with 1/2 tsp of honey, 2 hrs after the dinner and don’t eat anything else till you sleep. Amalaki Rasayan is prepared from Amla (Indian Gooseberry). Its scientific name is Phyllanthus emblica. It blocks production of excess DHT in the body. <span className="super">[21], [22], [23]</span></li>
    					</ol>
					</p>
					<p>
						If these measures don’t stop hair loss within 4 weeks, then one should consider investigating the thyroid function.
					</p>
					<p>
						In long term, one should also consider following changes in diet & lifestyle:
						<ol className='article_list'>
							<li>stop consuming sugar-based products</li>
    						<li>avoid snacking between meals</li>
    						<li>reduce stress</li>
    						<li>ensure daily exposure of skin to sunlight for 15-20 mins</li>
    						<li>avoid consuming Soy</li>
    						<li>drink buttermilk 15 mins after lunch. You may add a little black pepper but no salt. (This is to supplement good bacteria in your gut.) <span className="super">[24]</span></li>
    					</ol>
					</p>
					<p>
						Few medicines can also cause hair loss. If you are taking medication for any of the following, then consult your doctor about it:
						<ol className='article_list'>
							<li>Birth control pills</li>
    						<li>Antidepressants</li>
    						<li>Steroids</li>
    						<li>Insulin for diabetes control</li>
    						<li>Thyroid treatment (changing thyroid medicine & its dose, can help manage the hair loss) <span className="super">[25]</span></li>
    					</ol>
					</p>
					<p>
						Apart from this, one should consider controlling dandruff and avoiding haircare products which contain harsh chemicals. To learn about controlling dandruff refer <Link to='/article/soln_dandruff'>this</Link> article. To identify hair care products with harsh chemicals refer <Link to='/article/soln_haircare'>this</Link> article.
					</p>
					<p>
						PS: By adding curry leaves & Amalaki Rasayan in my daily routine, I managed to stop my hair loss completely, even though I have the problem of hair loss in my heredity. To learn more about details of my experience click <Link to='/article/blog_hairloss'>here</Link>.

					</p>
					<h3 className='article_heading'>References</h3>
					<ol className="reference_list">
						<li>
							Pattern hair loss, Wikipedia, <Link to='https://en.wikipedia.org/wiki/Pattern_hair_loss'>https://en.wikipedia.org/wiki/Pattern_hair_loss</Link>
						</li>
						<li>
							Thomas EA, Kadyan R S. Alopecia areata and autoimmunity: A clinical study. Indian journal of  dermatology 2008;53:70-4. <Link to='https://www.e-ijd.org/text.asp?2008/53/2/70/41650'>https://www.e-ijd.org/text.asp?2008/53/2/70/41650</Link>
						</li>
						<li>
							K Oba, S Kimura. Effects of vitamin A deficiency on thyroid function and serum thyroxine levels in the rat. Journal of nutritional science and vitaminology (Tokyo). 1980;26(4):327-34. <Link to='https://doi.org/10.3177/jnsv.26.327'>https://doi.org/10.3177/jnsv.26.327</Link>
						</li>
						<li>
							Vincent M, Yogiraj K. A descriptive study of alopecia patterns and their relation to thyroid dysfunction. international journal of trichology. 2013;5:57-60. <Link to='https://www.ijtrichology.com/text.asp?2013/5/1/57/114701'>https://www.ijtrichology.com/text.asp?2013/5/1/57/114701</Link>
						</li>
						<li>
							Glynis Ablon. A 3-month, randomized, double-blind, placebo-controlled study evaluating the ability of an extra-strength marine protein supplement to promote hair growth and decrease shedding in women with self-perceived thinning hair. Dermatology research and practice, vol. 2015, Article ID 841570, 8 pages, 2015. <Link to='https://doi.org/10.1155/2015/841570'>https://doi.org/10.1155/2015/841570</Link>
						</li>
						<li>
							Almohanna, H.M., Ahmed, A.A., Tsatalis, J.P. et al. The role of vitamins and minerals in hair loss: A review. Dermatology and therapy (Heidelb) 9, 51–70 (2019). <Link to='https://doi.org/10.1007/s13555-018-0278-6'>https://doi.org/10.1007/s13555-018-0278-6</Link>
						</li>
						<li>
							Lidiane Advincula de Araújo, Flavia Addor, et. al. Use of silicon for skin and hair care: an approach of chemical forms available and efficacy. Anais brasileiros de dermatologia. 2016 May-Jun; 91(3): 331–335. <Link to='https://doi.org/10.1590/abd1806-4841.20163986'>https://doi.org/10.1590/abd1806-4841.20163986</Link>
						</li>
						<li>
							These vitamin deficiencies can cause hair loss. WebMD, Connect to care, Hair loss. <Link to='https://www.webmd.com/connect-to-care/hair-loss/vitamin-deficiencies-that-can-cause-hair-loss'>https://www.webmd.com/connect-to-care/hair-loss/vitamin-deficiencies-that-can-cause-hair-loss</Link>
						</li>
						<li>
							Guo EL, Katta R. Diet and hair loss: effects of nutrient deficiency and supplement use. Dermatology practical & conceptual. 2017; 7(1):1. <Link to='https://doi.org/10.5826/dpc.0701a01'>https://doi.org/10.5826/dpc.0701a01</Link>
						</li>
						<li>
							Yves Schutz. Protein turnover, ureagenesis and gluconeogenesis. International journal for vitamin and nutrition research. Mar 2011; 81(2-3):101-7. <Link to='https://doi.org/10.1024/0300-9831/a000064'>https://doi.org/10.1024/0300-9831/a000064</Link>
						</li>
						<li>
							Kevin J. Kinter; Aabha A. Anekar. Biochemistry, Dihydrotestosterone. Treasure Island (FL): StatPearls publishing; 2021 Jan-. <Link to='https://www.ncbi.nlm.nih.gov/books/NBK557634/'>https://www.ncbi.nlm.nih.gov/books/NBK557634/</Link>
						</li>
						<li>
							Nikki Hill. Dihydrotestosterone (DHT) & hair loss. Skin of culture and hair center. Oct 16, 2018. <Link to='https://www.socahcenter.com/dihydrotestosterone-dht-hair-loss/'>https://www.socahcenter.com/dihydrotestosterone-dht-hair-loss/</Link>
						</li>
						<li>
							Messina M, Magee P. Does soy protein affect circulating levels of unbound IGF-1? European journal of nutrition. Mar 2018; 57(2):423-432. <Link to='https://doi.org/10.1007/s00394-017-1459-2'>https://doi.org/10.1007/s00394-017-1459-2</Link>
						</li>
						<li>
							Daniel Yetman. How to fight the baldness gene (and other factors). Healthline. Nov 24, 2020. <Link to='https://www.healthline.com/health/baldness-gene'>https://www.healthline.com/health/baldness-gene</Link>
						</li>
						<li>
							Daniel R. Schmidt, Sam R. Holmstrom, Klementina Fon Tacer, et al. Regulation of bile acid synthesis by fat-soluble vitamins A and D. Journal of biological chemistry. May 7 2010; 285(19): 14486–14494. <Link to='https://doi.org/10.1074/jbc.M110.116004'>https://doi.org/10.1074/jbc.M110.116004</Link>
						</li>
						<li>
							Jason M. Ridlon, Dae Joong Kang, Phillip B. Hylemon, et. al. Bile acids and the gut microbiome. Current opinion in gastroenterology. May 2014; 30(3): 332–338. <Link to='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4215539/'>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4215539/</Link>
						</li>
						<li>
							Shashikant Malkud. Telogen Effluvium: A Review. Journal of clinical and diagnostic research. 2015 Sep; 9(9): WE01–WE03. <Link to='https://doi.org/10.7860/JCDR/2015/15219.6492'>https://doi.org/10.7860/JCDR/2015/15219.6492</Link>
						</li>
						<li>
							Health Benefits of Curry Leaf. WebMD, Nourish, Diet & weight management. <Link to='https://www.webmd.com/diet/health-benefits-curry-leaf#1'>https://www.webmd.com/diet/health-benefits-curry-leaf#1</Link>
						</li>
						<li>
							Rakhee Maloo. Curry leaves health benefits. MedIndia, Diet & Nutrition, Jun 13 2018. <Link to='https://www.medindia.net/patients/lifestyleandwellness/curry-leaves-health-benefits.htm'>https://www.medindia.net/patients/lifestyleandwellness/curry-leaves-health-benefits.htm</Link>
						</li>
						<li>
							Jane Chertoff. 19 High-protein vegetables and how to eat more of them. Healthline. Aug 20, 2019. <Link to='https://www.healthline.com/health/food-nutrition/19-high-protein-vegetables'>https://www.healthline.com/health/food-nutrition/19-high-protein-vegetables</Link>
						</li>
						<li>
							Naphatsorn Kumara, Wandee Rungseevijitprapa et. al. 5α-reductase inhibition and hair growth promotion of some Thai plants traditionally used for hair treatment. Journal of ethnopharmacology
Volume 139, Issue 3, Feb 15 2012, 765-771. <Link to='https://www.sciencedirect.com/science/article/abs/pii/S0378874111008774?via%3Dihub'>https://www.sciencedirect.com/science/article/abs/pii/S0378874111008774?via%3Dihub</Link>
						</li>
						<li>
							K. R. Srikantha Murthy, Vagbhata’s Astanga Hrdayam, Volume-1, Chapter-6, Chowkhamba Krishnadas Academy, Varanasi, Reprint 2019.
						</li>
						<li>
							Princy Purwar, S. S. Yadav, et. al. Khalitya (hair fall) management - Ayurvedic perspective. International journal of health sciences and research. Volume 9, Issue 4. April 2019. <Link to='https://www.ijhsr.org/IJHSR_Vol.9_Issue.4_April2019/34.pdf'>https://www.ijhsr.org/IJHSR_Vol.9_Issue.4_April2019/34.pdf</Link>
						</li>
						<li>
							Dan Brennan. Buttermilk: Are there health benefits? WebMD, Nourish, Diet & weight management. Nov 23, 2020. <Link to='https://www.webmd.com/diet/health-benefits-buttermilk#1'>https://www.webmd.com/diet/health-benefits-buttermilk#1</Link>
						</li>
						<li>
							Stephanie S. Gardner. Medications that can cause hair loss. WebMD, Skin problems and treatments,  Hair loss. Nov 12, 2020. <Link to='https://www.webmd.com/skin-problems-and-treatments/hair-loss/drug-induced-hair-loss-2'>https://www.webmd.com/skin-problems-and-treatments/hair-loss/drug-induced-hair-loss-2</Link>
						</li>
					</ol>
				</div>
		</Article>
	);
}

const TakeawayData = [
	{
		takeaway: 'Every morning chew 4 curry leaves',
	},
	{
		takeaway: 'Add sprouts, pulses & soaked almonds to the diet',
	},
	{
		takeaway: 'Take 1/3rd tsp of Amalaki Rasayan, 2 hrs after dinner',
	},
];

const RelatedArticles = [
	{
		title: 'Blog - Hairloss',
		path: '/article/blog_hairloss',
	},
	{
		title: 'Solution - Dandruff',
		path: '/article/soln_dandruff',
	},
	{
		title: 'Solution - Haircare',
		path: '/article/soln_haircare',
	},
];

